<template>
  <div class="we" id="we">
    <div class="title">生来敢玩</div>
    <div class="play-btn" @click="play">
      <img src="../img/big_play_icon.svg" alt="icon">
    </div>
    <p class="desc">自2014年10月创办以来，敢玩专注于极限户外和娱乐体育。</p>
    <p class="desc">从顽童、玩具、玩法三个方面，产出更专注于‘玩’的内容，</p>
    <p class="desc">已打造了一系列深受喜爱的娱乐体育真人秀和引爆网络的运动视频。</p>
    <div class="banner-player" :class="{'layer': videoShow, 'reset': !videoShow}">
      <iframe
        src="//player.bilibili.com/player.html?aid=375588815&bvid=BV12s41147vq&cid=339262048&page=1&high_quality=1&danmaku=0"
        allowfullscreen="allowfullscreen"
        width="100%"
        height="100%"
        scrolling="no"
        frameborder="0"
        sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"></iframe>
    </div>
    <div :class="{'close': videoShow}" @click="close"></div>
  </div>
</template>

<script>

export default {
  name: 'we',
  data () {
    return {
      videoShow: false,
      isMuted: true
    }
  },
  props: {
    banner: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.$el.style.height = this.$el.offsetWidth * 0.5625 + 'px'
    this.$el.style.paddingTop = (parseFloat(this.$el.style.height) - 76) / 2 - 70 + 'px'

    window.addEventListener('resize', () => {
      this.$el.style.height = this.$el.offsetWidth * 0.5625 + 'px'
      this.$el.style.paddingTop = (parseFloat(this.$el.style.height) - 76) / 2 - 70 + 'px'
    })
  },
  methods: {
    play () {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'

      this.videoShow = true
      this.isMuted = false
    },
    close () {
      document.body.style.height = ''
      document.body.style.overflow = ''

      this.videoShow = false
      this.isMuted = true
    }
  }
}
</script>

<style lang="sass">
  @import '../style/components/we.scss'
</style>
