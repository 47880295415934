<template>
  <div class="original" id="original">
    <div class="original-cover-wrap">
      <div class="title">敢玩原创视频</div>
      <div class="swiper">
        <div class="swiper-wrap">
          <div class="swiper-main" layout="row" layout-align="center center">
            <div class="swiper-item" v-for="(album, index) of availableColumn" :class="{'cover-move-left': moveToLeft, 'cover-move-right': moveToRight}" @transitionend="stopMove">
              <div class="swiper-item-wrap" :class="{'cover-active': album.active}">
                <div class="cover-3d" @mouseenter="mouseEnter(index)" @mouseleave="mouseLeave(index)">
                   <img class="swiper-item-cover" :src="album.cover" @click="changeAlbum(index)">
                </div>
              </div>
              <div class="swiper-item-title" :class="{'title-active': album.active}" layout="row" layout-align="center center">
                <div class="title-name">{{ album.title }}</div>
                <div class="pipe"></div>
                <div class="title-count">{{ album.play_count }}播放</div>
              </div>
            </div>
          </div>
          <div class="right-side-wrap" :class="{'right-side-move-pre': moveToLeft, 'right-side-move-next': moveToRight}" v-show="rightSideImage !== ''">
            <img :src="rightSideImage" alt="">
          </div>
          <div class="left-side-wrap" :class="{'left-side-move-pre': moveToLeft, 'left-side-move-next': moveToRight}" v-show="leftSideImage !== ''">
            <img :src="leftSideImage" alt="">
          </div>
          <div class="needle" :style="{left: needleLeft + 'px'}" v-show="isNeedleShow"></div>
        </div>
        <div class="swiper-action-left" v-show="!leftEnd" @click="startMove('right')"></div>
        <div class="swiper-action-right" v-show="!rightEnd" @click="startMove('left')"></div>
      </div>
    </div>
    <div class="drama" v-show="isDramaShow">
      <div class="drama-title" layout="row" layout-align="center center">
        <img src="../img/triangle_left.svg" alt="icon">
        <span class="txt">{{ albumTitle }}</span>
        <img src="../img/triangle_right.svg" alt="icon">
      </div>
      <div class="play-count">{{ playCount }}总播放</div>
      <div class="video-list">
        <div class="video-item" @click="playVideo(index)" v-for="(video, index) in videoList">
          <div class="cover-wrap">
            <img class="cover" :src="video.cover" alt="cover">
            <img class="play-icon" src="../img/play-icon.svg"  alt="icon">
          </div>
          <div class="video-title">{{ video.title }}</div>
        </div>
      </div>
      <div class="more-list-animation">
        <div class="video-list more-list">
          <div class="video-item" @click="playMoreVideo(index)" v-for="(video, index) in videoListMore">
            <div class="cover-wrap">
              <img class="cover" :src="video.cover" alt="cover">
              <img class="play-icon" src="../img/play-icon.svg"  alt="icon">
            </div>
            <div class="video-title">{{ video.title }}</div>
          </div>
        </div>
      </div>
      <div class="more-btn" :class="{'change-btn': showMore}"  @click="more" v-show="isMoreBtnShow">
        <img src="../img/triangle_down.svg" alt="">
        <span class="btn-text">{{ btnText }}</span>
        <img src="../img/triangle_down.svg" alt="">
      </div>
    </div>
    <transition name="fade">
      <div class="player-wrap" v-show="playerShow">
        <div class="close-icon" @click="closeVideoPlayer"></div>
        <iframe
          id="player"
          :src="playerUrl"
          allowfullscreen="allowfullscreen"
          width="100%"
          height="100%"
          scrolling="no"
          frameborder="0"
          sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts"></iframe>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: 'original',
  data () {
    return {
      isRetina: '',
      isActive: '',
      albumIndex: 1,
      needleLeft: 542,
      rightSideImage: '',
      leftSideImage: '',
      leftEnd: false,
      rightEnd: false,
      moveToRight: false,
      moveToLeft: false,
      clickLeft: -1,
      clickRight: 0,
      isNeedleShow: true,
      playCount: '',
      albumTitle: '',
      isDramaShow: true,
      videoList: [],
      videoListMore: [],
      isMoreBtnShow: true,
      showMore: false,
      btnText: '更多视频',
      playerShow: false,
      playerUrl: ''
    }
  },
  props: {
    column: {
      type: Array,
      required: true
    }
  },
  watch: {
    column: function () {
      this.availableColumn[this.albumIndex].active = true
      this.playCount = this.availableColumn[this.albumIndex].play_count
      this.albumTitle = this.availableColumn[this.albumIndex].title

      if (this.availableColumn[this.albumIndex].videos.length <= 4) {
        this.videoList = this.availableColumn[this.albumIndex].videos
        this.isMoreBtnShow = false
      } else {
        this.videoListMore = this.availableColumn[this.albumIndex].videos.slice(4)
        this.videoList = this.availableColumn[this.albumIndex].videos.slice(0, 4)
      }
    }
  },
  mounted () {
  },
  methods: {
    changeAlbum (index) {
      this.isDramaShow = true
      this.isNeedleShow = true
      this.videoListMore = []
      this.playCount = this.availableColumn[index].play_count
      this.albumTitle = this.availableColumn[index].title

      if (index === 0) {
        this.needleLeft = 202
      } else if (index === 1) {
        this.needleLeft = 542
      } else if (index === 2) {
        this.needleLeft = 882
      }

      if (this.availableColumn[index].videos.length <= 4) {
        this.videoList = this.availableColumn[index].videos
        this.isMoreBtnShow = false
      } else {
        this.videoListMore = this.availableColumn[index].videos.slice(4)
        this.videoList = this.availableColumn[index].videos.slice(0, 4)
        this.isMoreBtnShow = true
      }

      if (this.showMore === true) {
        this.more()
        this.showMore === false
      }

      this.availableColumn.forEach((album, i) => {
        if (i === index) {
          album.active = true
        } else {
          album.active = false
        }
      })
    },
    changeCount (count) {
      if (count > 10000 && count < 100000000) {
        count = `${(count / 10000).toFixed(0)}万`
      } else if (count >= 100000000) {
        count = `${(count / 100000000).toFixed(0)}亿`
      }

      return count
    },
    moveLeft () {
      if (this.filterColumn.length === 4) {
        this.leftEnd = true
        this.rightEnd = false

        this.clickLeft += 1
        this.clickRight -= 1

        this.leftSideImage = ''
        this.rightSideImage = this.filterColumn[3].cover
      } else if (this.filterColumn.length >= 5 && this.clickLeft < 0) {
        this.clickLeft += 1
        this.clickRight -= 1

        if (this.clickLeft === 0) {
          this.leftSideImage = ''
          this.rightSideImage = this.filterColumn[Math.abs(this.clickLeft) + 3].cover
          this.leftEnd = true
          return
        } else {
          this.rightSideImage = this.filterColumn[Math.abs(this.clickLeft) + 3].cover
          this.leftSideImage = this.filterColumn[Math.abs(this.clickLeft) - 1].cover
          this.rightEnd = false
        }
      }
    },
    moveRight () {
      if (this.filterColumn.length === 4) {
        this.leftEnd = false
        this.rightEnd = true

        this.clickLeft -= 1
        this.clickRight += 1

        this.leftSideImage = this.filterColumn[0].cover
        this.rightSideImage = ''
      } else if (this.filterColumn.length >= 5 && this.clickRight < this.column.length - 3) {
        this.clickLeft -= 1
        this.clickRight += 1

        if (this.clickRight === this.column.length - 4) {
          this.rightSideImage = ''
          this.leftSideImage = this.filterColumn[Math.abs(this.clickRight)].cover
          this.rightEnd = true
          return
        } else {
          this.rightSideImage = this.filterColumn[Math.abs(this.clickLeft) + 3].cover
          this.leftSideImage = this.filterColumn[Math.abs(this.clickLeft) - 1].cover
          this.leftEnd = false
        }
      }
    },
    startMove (target) {
      this.column.map((album) => {
        album.active = false
      })

      this.isDramaShow = false
      this.isNeedleShow = false

      if (target === 'left') {
        this.moveToLeft = true
      } else if (target === 'right') {
        this.moveToRight = true
      }
    },
    stopMove () {
      if (this.moveToLeft === true) {
        this.moveToLeft = false
        this.moveRight()
      } else if (this.moveToRight === true) {
        this.moveToRight = false
        this.moveLeft()
      }
    },
    playVideo (index) {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'

      this.playerUrl = this.videoList[index].play_url
      this.playerShow = true
    },
    playMoreVideo (index) {
      document.body.style.height = '100%'
      document.body.style.overflow = 'hidden'

      this.playerUrl = this.videoListMore[index].play_url
      this.playerShow = true
    },
    closeVideoPlayer () {
      document.body.style.height = ''
      document.body.style.overflow = ''

      this.playerUrl = ''
      this.playerShow = false
    },
    more () {
      var wrap = document.querySelector('.more-list-animation')

      if (this.showMore) {
        wrap.style.height = '80px'
        wrap.style.opacity = 0
      } else {
        var moreList = wrap.children[0]
        var h = moreList.getBoundingClientRect().height

        wrap.style.height = h + 80 + 'px'
        wrap.style.opacity = 1
      }

      this.showMore = !this.showMore

      this.btnText = this.showMore ? '收起更多' : '更多视频'
    },
    mouseEnter (index) {
      var el = document.querySelectorAll('.cover-3d')[index]
      var img = el.children[0]

      var thisPX = el.getBoundingClientRect().left
      var thisPY = el.getBoundingClientRect().top
      var boxWidth = el.getBoundingClientRect().width
      var boxHeight = el.getBoundingClientRect().height
      var scrollTop = document.documentElement.scrollTop + document.body.scrollTop

      el.addEventListener('mousemove', function (event) {
        var mouseX = event.pageX - thisPX
        var mouseY = event.pageY - scrollTop - thisPY
        var x
        var y

        x = mouseX - boxWidth / 2
        y = boxHeight / 2 - mouseY

        el.style.transform = 'rotateY(' + x / 15 + 'deg) ' + 'rotateX(' + y / 15 + 'deg)'
        img.style.boxShadow = parseInt(-x / 20) + 'px ' + parseInt(y / 20) + 'px 20px rgba(0, 0, 0, 0.15)'
      })
    },
    mouseLeave (index) {
      var el = document.querySelectorAll('.cover-3d')[index]
      var img = el.children[0]

      el.style.transform =  'rotateY(0deg) rotateX(0deg)'
      img.style.boxShadow = ''
    }
  },
  computed: {
    filterColumn: function () {
      return this.column.map((album) => {
        album.play_count = this.changeCount(album.play_count)

        return album
      })
    },
    availableColumn: function ({clickLeft}) {
      if (clickLeft !== -1) {
        if (this.filterColumn.length === 4) {
          return this.filterColumn.slice(1, 4)
        }
        return this.filterColumn.slice(Math.abs(this.clickLeft), Math.abs(this.clickLeft) + 3)
      } else {
        if (this.filterColumn.length <= 3) {
          this.leftEnd = true
          this.rightEnd = true

          return this.filterColumn
        } else if (this.filterColumn.length === 4) {
          this.rightSideImage = this.filterColumn[3].cover
          this.leftEnd = true
          this.rightEnd = false

          return this.filterColumn.slice(0, 3)
        } else if (this.filterColumn.length >= 5) {
          this.leftSideImage = this.filterColumn[0].cover
          this.rightSideImage = this.filterColumn[4].cover

          this.leftEnd = false
          this.rightEnd = false

          return this.filterColumn.slice(1, 4)
        }
      }
    }
  }
}
</script>

<style lang="sass">
  @import '../style/components/original.scss'
  @import '~video.js/dist/video-js.min.css'
</style>
