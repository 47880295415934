<template>
  <div class="footer" id="footer">
    &copy; 2022, Ganwan (Beijing) Technology Co., Ltd. ALL RIGHTS RESERVED. 敢玩（北京）科技有限公司 京ICP备15004334号-1
  </div>
</template>

<script>
export default {
  name: 'bottom',
  data () {
    return {
    }
  }
}
</script>

<style lang="sass">
  @import '../style/components/footer.scss'
</style>
