import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    isRetina: false
  },
  mutations: {
    SET_RETINA (state, isRetina) {
      state.isRetina = isRetina
    }
  },
  actions: {
    setRetina ({commit}, isRetina) {
      commit('SET_RETINA', isRetina)
    }
  },
  getters: {
    getRetina: (state) => state.isRetina
  }
})

export default store
