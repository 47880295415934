<template>
  <div class="contact" id="contact">
    <div class="title">联系敢玩</div>
    <div class="contact-list" layout="row" layout-align="start center">
      <div class="left-list">
        <div class="item">
          <img class="icon" src="../img/wechat_contact.svg" alt="">
          <img class="icon-hover" src="../img/wechat_contact_hover.svg" alt="">
          <span>公众号：敢玩</span>
          <p class="qrcode-wrap">
            <img class="wechat-qrcode" src="../img/wechat_qrcode@2x.png" v-if="isRetina" alt="qrcode">
            <img class="wechat-qrcode" src="../img/wechat_qrcode.png" v-else alt="qrcode">
          </p>
        </div>
        <p class="item">
          <a href="http://weibo.com/u/5228293705?topnav=1&wvr=6&topsug=1" target="blank">
            <img class="icon" src="../img/weibo_contact.svg" alt="">
            <img class="icon-hover" src="../img/weibo_contact_hover.svg" alt="">
            <span>微博：@敢玩idarex</span>
          </a>
        </p>
        <p class="item">
          <a href="http://i.youku.com/i/UMjk5Mzg1NjA5Mg==?spm=a2h0k.8191405.0.0" target="blank">
            <img class="icon" src="../img/youku.svg" alt="">
            <img class="icon-hover" src="../img/youku_hover.svg" alt="">
            <span>优酷：敢玩原创</span>
          </a>
        </p>
      </div>
      <div class="right-list">
        <p class="item">业务合作：<a href="mailto:bd@idarex.com">bd@idarex.com</a></p>
        <p class="item">电话：186 1406 7727（张先生）</p>
        <p class="item">地址：北京市朝阳区东风艺术区北区18-7</p>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>

<script>
import Bottom from './Bottom'

export default {
  name: 'contact',
  data () {
    return {
      isRetina: ''
    }
  },
  mounted () {
    this.isRetina = this.$store.getters.getRetina
  },
  components: {
    Bottom
  }
}
</script>

<style lang="sass">
  @import '../style/components/contact.scss'
</style>
