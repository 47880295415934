/*
*
* This file is don't work,
* becsuse vue-router must set <roure-view> tag to show changed component,
* but now can't use <roure-view> tag, so change routers use history API and location.hash API(hack IE)
*
*/

import Vue from 'vue'
import Router from 'vue-router'

import We from '../components/We'
import Original from '../components/Original'
import Download from '../components/Download'
import Contact from '../components/Contact'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      component: We
    },
    {
      path: '/original',
      name: 'original',
      component: Original
    },
    {
      path: '/download',
      name: 'download',
      component: Download
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    }
  ]
})

export default router
