<template>
  <div class="nav" :class="{'nav-black': !isNavChange, 'nav-light': isNavChange}" layout="row" layout-align="space-between center">
    <div class="logo">
      <img src="../img/logo_zh@2x.png" v-if="isRetina" alt="logo">
      <img src="../img/logo_zh.png" v-else alt="logo">
    </div>
    <ul class="nav-list" layout="row" layout-align="start center">
      <li class="nav-item" :class="{'active': navIndex == index}" v-for="(nav, index) in navList" @click="scroll(index)">{{ nav.name }}</li>
      <li class="pipe">|</li>
      <li class="nav-icon-wechat">
        <div class="wechat-icon"></div>
        <div class="wechat-qrcode">
          <img src="../img/wechat_qrcode@2x.png" v-if="isRetina" alt="qrcode">
          <img src="../img/wechat_qrcode.png" v-else alt="qrcode">
        </div>
      </li>
      <a href="http://weibo.com/u/5228293705?topnav=1&wvr=6&topsug=1" target="blank">
        <li class="nav-icon-weibo">
          <div class="weibo-icon"></div>
        </li>
      </a>
    </ul>
  </div>
</template>

<script>
const isWebkit = navigator.userAgent.toLowerCase().match(/webkit\/([\d.]+)/)

export default {
  name: 'navbar',
  data () {
    return {
      isRetina: '',
      isNavChange: false,
      navList: [
        {name: '我们'},
        {name: '原创视频'},
        {name: 'App'},
        {name: '联系我们'}
      ],
      navIndex: 0
    }
  },
  mounted () {
    this.isRetina = this.$store.getters.getRetina

    this.active(this.navIndex)

    window.addEventListener('scroll', () => {
      window.requestAnimationFrame(() => {
        let scrollTop = document.body.scrollTop || document.documentElement.scrollTop

        this.handleScroll(scrollTop)
        this.changeNav(scrollTop)
      })
    })
  },
  methods: {
    changeNav (scrollTop) {
      if (scrollTop >= (this.$el.offsetWidth * 0.5625 - 72)) {
        this.isNavChange = true
      } else {
        this.isNavChange = false
      }
    },
    handleScroll (scrollTop) {
      if (scrollTop < this.$el.offsetWidth * 0.5625 - 72) {
        this.active(0)
      } else if (scrollTop >= this.$el.offsetWidth * 0.5625 - 72 && scrollTop < document.getElementById('download').offsetTop - 72) {
        this.active(1)
      } else if (scrollTop >= document.getElementById('download').offsetTop - 72 && document.documentElement.offsetHeight - (scrollTop + document.documentElement.clientHeight) > 100) {
        this.active(2)
      } else if (document.documentElement.offsetHeight - (scrollTop + document.documentElement.clientHeight) <= 100) {
        this.active(3)
      }
    },
    active (index) {
      this.navIndex = index
    },
    scroll (index) {
      let offset

      if (index === 0) {
        offset = document.querySelector('#we').getBoundingClientRect().top
      } else if (index === 1) {
        offset = document.querySelector('#original').getBoundingClientRect().top
      } else if (index === 2) {
        offset = document.querySelector('#download').getBoundingClientRect().top
      } else if (index === 3) {
        offset = document.querySelector('#contact').getBoundingClientRect().top
      }

      let requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame

      let direction

      if (offset > 0) {
        direction = 0
        offset -= 71
      } else {
        direction = 1
        offset -= 71
      }

      let move = offset
      move = Math.abs(move)

      let span = Math.floor(move / 15)

      function step () {
        if (direction === 0 && Math.floor(move) > 0) {
          if (move < span) {
            span = move
          }

          if (!isWebkit) {
            document.documentElement.scrollTop += span
          } else {
            document.body.scrollTop += span
          }

          move -= span

          requestAnimationFrame(step)
        } else if (direction === 1 && Math.floor(move) > 0) {
          if (move < span) {
            span = move
          }

          if (!isWebkit) {
            document.documentElement.scrollTop -= span
          } else {
            document.body.scrollTop -= span
          }

          move -= span

          requestAnimationFrame(step)
        } else {
          return
        }
      }
      requestAnimationFrame(step)
    },
    changeScroll () {
      this.isScroll = false
    }
  }
}
</script>

<style lang="sass">
  @import '../style/components/navbar.scss'
</style>{
