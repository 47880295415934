<template>
  <div id="app">
    <navbar></navbar>
    <we :banner="banner"></we>
    <original :column="column"></original>
    <download></download>
    <contact></contact>
    <back-top></back-top>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import We from './components/We'
import Original from './components/Original'
import Download from './components/Download'
import Contact from './components/Contact'
import BackTop from './components/BackTop'

export default {
  name: 'app',
  data () {
    return {
      banner: {
        play_url: ''
      },
      column: [],
    }
  },
  beforeCreate () {
    if (window.devicePixelRatio > 1) {
      this.$store.dispatch('setRetina', true)
    }
  },
  mounted () {
    fetch('./data.json')
      .then(response => response.json())
      .then(data => {
        data.forEach(function (album) {
          album.videos.forEach(function (video) {
            video.play_url = `//player.bilibili.com/player.html?aid=375588815&bvid=${video.play_url}&cid=339262048&page=1&high_quality=1&danmaku=0`;
            video.cover = `./covers/${video.cover}`;
          });
        });
        this.column = data;
      });
    /*
    this.$http.get('./data.json').then((res) => {
      res.body.columns.forEach(function (album) {
        album.videos.forEach(function (video) {
          video.play_url = `//player.bilibili.com/player.html?aid=375588815&bvid=${video.play_url}&cid=339262048&page=1&high_quality=1&danmaku=0`;
          video.cover = `./covers/${video.cover}`;
        });
      });

      //this.column = res.body.columns;
    }, (res) => {
      // error
    })
    */
  },
  components: {
    Navbar,
    We,
    Original,
    Download,
    Contact,
    BackTop
  }
}
</script>

<style>
 @import './style/base.scss'
</style>
