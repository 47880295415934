<template>
  <div class="iconfont" v-show="showGoTop" @click="goTop">
    <img class="icon-top" src="../img/iconfont-rocket.png">
  </div>
</template>

<script>
export default {
  name: 'BackTop',
  data () {
    return {
      showGoTop: false
    }
  },
  methods: {
    goTop () {
      window.scrollTo(0, 0)
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      window.requestAnimationFrame(() => {
        if (document.body.scrollTop > 600) {
          this.showGoTop = true
        } else {
          this.showGoTop = false
        }
      })
    })
  }
}
</script>

<style lang="sass">
  @import '../style/components/backToTop.scss'
</style>
